import React, {Component} from "react";
import { Badge, Card, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "components/Headers/Header.js";
import ApiService from "../../services/classes/Api";
import DataTableComponent from "../../components/Tables/DataTable";
import moment from 'moment';
import {Button} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudDownloadAlt, faPlus} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/General/Loading";
import RecordDataForm from "../../components/General/RecordDataForm";
import {Slide, toast} from "react-toastify";
import Validator from "../../helpers/Validations";

class DocList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			documents: [],
			page: 1,
			limit: 10,
			loading: false
		};
		this.export = this.export.bind(this);
	}

	componentDidMount() {
		this.loadData();
	}

	loadData() {
		this.setState({loading: true});
		const { page, limit } = this.state;
		ApiService.graph(`
      {
        recordsFeed(type: "doc", page: ${page}, limit: ${limit} ) { records { _id state data createdAt updatedAt } pagination { total lastPage } }
        topology(name: "doc") { _id name plural_label label shape states }  
        topologies { _id name plural_label label shape states }  
      }
    `).then(response => {
			this.setState({loading: false});
			if(response.status === 200)
				this.setState({
					documents: response.data.data.recordsFeed.records,
					topology: response.data.data.topology,
					topologies: response.data.data.topologies,
					total: response.data.data.recordsFeed.pagination.total,
					lastPage: response.data.data.recordsFeed.pagination.lastPage
				});
			else
				alert("error");
		}).catch(e => alert("error"))
	}

	createPage(data) {
		ApiService.createRecord("doc", data, "new").then(response => {
			toast("Documento aggiunto correttamente.", {
				transition: Slide,
				closeButton: true,
				autoClose: 2000,
				position: 'bottom-center',
				type: 'success'
			});
			this.loadData();
		}).catch(e => {
			toast("Si è verificato un problema, riprova più tardi.", {
				transition: Slide,
				closeButton: true,
				autoClose: 2000,
				position: 'bottom-center',
				type: 'error'
			});
		});
	}

	editLead(oid) {
		window.location.href = "/admin/" + oid
	}

	export() {
		this.setState({loading: true});
		ApiService.export("doc").then(response => response.blob()).then(blob => {

			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `export_${moment(new Date()).format("YYYY-MM-DD_HH-mm")}.xlsx`);
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);

			this.setState({loading: false});
		})
	}

	setPage(page) {
		this.setState({ page }, () => this.loadData());
	}

	render() {

		if(!this.state.documents || !this.state.topology) {
			return <Loading />
		}

		const { documents, loading, topology, topologies, page, total, limit, lastPage } = this.state;

		return (
			<>
				<Header />
				{/* Page content */}
				<Container className="mt--7" fluid>
					{/* Table */}
					<Row>
						<div className="col">
							<DataTableComponent
								title={"Lista Foglietti"}
								actions={
									<>
										<AddLeadDialog confirmCallback={(data) => this.createPage(data)} topology={topology} topologies={topologies} button={<Button color={"success"} style={{background: "#2dce89", color: "#fff"}} variant={"contained"}startIcon={<FontAwesomeIcon icon={faPlus} size="1x" />} >Nuovo Foglietto</Button>} />
										<span> </span>
										<Button onClick={this.export} color={"info"} variant={"outlined"} startIcon={<FontAwesomeIcon icon={faCloudDownloadAlt} size="1x" />}>Esporta</Button>
									</>
								}
								data={documents}
								loading={loading}
								onClick={(r) => this.editLead(r)}
								page={page}
								total={total}
								lastPage={lastPage}
								setPage={(page) => this.setPage(page)}
								schema={[
									{
										name: "Nome",
										selector: (r) => r.data.name
									},
									// {
									// 	name: "Stato",
									// 	selector: (r) => <StateBadge state={r.state} />
									// },
									// {
									// 	name: "Data Creazione",
									// 	selector: (r) => moment(parseInt(r.createdAt)).format("DD/MM/YYYY, HH:mm:ss")
									// }
								]}
							/>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}

class AddLeadDialog extends Component {

	state = {
		modal: false,
		data: {}
	};

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.confirm = this.confirm.bind(this);
		this.isDataValid = this.isDataValid.bind(this);
	}

	toggle() {
		this.setState(previousState => ({modal: !previousState.modal}) )
	}

	isDataValid() {
		const state = "new";
		const { data } = this.state;
		const { topology } = this.props;
		const result = Validator.validate(data, topology, state);
		console.log(result);
		return result.length <= 0;
	}

	confirm() {
		const { data } = this.state;
		if(this.isDataValid()) {
			this.props.confirmCallback({...data});
			this.toggle();
		}
	}

	render() {

		const { name, label, plural_label } = this.props.topology;

		return (
			<React.Fragment>
				<span className="d-inline-block">
					<span onClick={this.toggle}> {this.props.button}</span>
						<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
      	    	<ModalHeader toggle={this.toggle}>Crea nuovo { label }</ModalHeader>
	    	    	<ModalBody>
								<Card body className="card-shadow-primary border mb-3">
                  <RecordDataForm state={"new"} updateRecordData={(data) => this.setState({data})} topology={this.props.topology} data={{}} topologies={this.props.topologies} />
		  	    	  </Card>
	    	    	</ModalBody>
      	    	<ModalFooter>
      	    	  <Button outline color="danger" className={"border-0 btn-transition"} onClick={this.toggle}> Annulla </Button>
      	    	  <Button color="success" onClick={this.confirm}> Salva </Button>
      	    	</ModalFooter>
      	   </Modal>
				</span>
			</React.Fragment>
		)
	}
};

const StateBadge = (props) => {
	const { state } = props;
	switch(state) {
		case "new":
			return <Badge style={{width: "100%"}} color="info"> In bozza </Badge>;
			break;
		case "live":
			return <Badge style={{width: "100%"}} color={"success"}> Live </Badge>;
		default:
			return <Badge style={{width: "100%"}} color={"secondary"} className={"badge-dot mr-4"}><i className={"bg-secondary"} /> Indefinito </Badge>;
	}
};

export default DocList;
