import SchemaSettings from "./views/System/Schema";
import UsersSettings from "./views/System/Users";
import EditRecord from "./views/EditRecord";
import DocList from "./views/pfizer/DocList";

const routes = [

  {
    inMenu: true,
    path: "/",
    name: "Foglietti Illustrativi",
    icon: "ni ni-planet text-blue",
    component: DocList,
    layout: "/admin"
  },
  {
    inMenu: false,
    path: "/:oid",
    name: "Modifica Categoria",
    icon: "ni ni-planet text-blue",
    component: EditRecord,
    layout: "/admin"
  },
  {
    inMenu: false,
    path: "/schema",
    name: "Schema Dati",
    icon: "ni ni-ui-04",
    component: SchemaSettings,
    layout: "/system"
  },
  {
    inMenu: false,
    path: "/users",
    name: "Utenti",
    icon: "ni ni-ui-04",
    component: UsersSettings,
    layout: "/system"
  }
];
export default routes;
