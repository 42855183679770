import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import { ToastContainer, toast } from 'react-toastify';

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import SystemLayout from "layouts/System.js";
import UserService from "./services/User";
import IDE from "./layouts/IDE";


class ApplicationEntryPoint extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {


    return(
      <>
        <ToastContainer />
        <BrowserRouter>
          <Switch>
            <Route path="/admin" render={props => <AdminLayout {...props}  />} />
            <Route path="/auth" render={props => <AuthLayout {...props} />} />
            <Route path="/system" render={props => <SystemLayout {...props} />} />
            <Route path="/ide" render={props => <IDE {...props} />} />
            <Redirect from="/" to="/admin/" />
          </Switch>
        </BrowserRouter>
      </>
    )
  }
}


ReactDOM.render(
  <ApplicationEntryPoint />,
  document.getElementById("root")
);
