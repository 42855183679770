import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import cookies from 'react-cookies';
import { Container } from "reactstrap";

import UserService from "../services/User";
import AuthService from "../services/classes/Auth";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import Auth from "./Auth";
import {CircularProgress} from "@material-ui/core";

class Admin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      logged: false,
      isCheckingAuthentication: true
    };
    this._loginHandler = this._loginHandler.bind(this);
    this._doLogoutHandler = this._doLogoutHandler.bind(this);
  }

  componentDidMount() {
    UserService.isLogged().then(logged => {
      this.setState({ logged: logged });
      this.setState({ isCheckingAuthentication: false })
    });
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  render() {

    if(this.state.isCheckingAuthentication)
      return <CircularProgress />;

    if (!this.state.logged)
      return <Auth loginHandler={this._loginHandler} />;

    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("assets/img/brand/logo.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/admin/" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }

  _loginHandler() {
    this.setState({
      logged: UserService.isLogged()
    });
    cookies.save("access_token", AuthService.getToken(), {path: "/"});
  }

  _doLogoutHandler() {
    UserService.logout();
    this.setState({
      logged: UserService.isLogged()
    });
    cookies.remove("access_token", {path: "/"});
  }
}

export default Admin;
